import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Flash = makeShortcode("Flash");
const Card = makeShortcode("Card");
const Column = makeShortcode("Column");
const Box = makeShortcode("Box");
const Video = makeShortcode("Video");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "-módulo-0---una-introducción-a-kernel",
      "style": {
        "position": "relative"
      }
    }}>{`🌠 Módulo 0 - Una introducción a Kernel`}<a parentName="h1" {...{
        "href": "#-m%C3%B3dulo-0---una-introducci%C3%B3n-a-kernel",
        "aria-label": " módulo 0   una introducción a kernel permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p>{`Kernel es un curso de 8 semanas en donde cubriremos un nuevo módulo de aprendizaje cada semana.
Cada módulo esta compuesto de dos secciones: `}<strong parentName="p">{`Lecturas elaboradas`}</strong>{`, en donde esperamos que
hayas leído antes del comienzo de cada semana; y `}<strong parentName="p">{`Material escogido`}</strong>{` que esta compuesto de
artículos, videos, y ocasionalmente podcast que `}<em parentName="p">{`altamente recomendamos`}</em>{` que revises antes de
las conversaciones íntimas de cada semana.`}</p>
    <p>{`La Ruta de Aprendizaje tiene como objetivo proporcionar un marco holístico de lo que es Web 3, sus principios,
su historia y estado actual. Sabemos que cada persona aprende de forma diferente, de ahí la mezcla de
texto, video y audio. Las "Lecturas elaboradas" tienen emojis junto a ellas en el menú y necesitarás
haber leído ambas piezas para poder participar plenamente durante las actividades de la semana. El "Material escogido" es altamente
recomendado si eres el tipo de persona que se siente que podría querer añadir valor a cualquiera
de las conversaciones espontáneas que sin duda surgirán en el transcurso del trabajo.`}</p>
    <h2 {...{
      "id": "repetición-espaciada",
      "style": {
        "position": "relative"
      }
    }}>{`Repetición espaciada`}<a parentName="h2" {...{
        "href": "#repetici%C3%B3n-espaciada",
        "aria-label": "repetición espaciada permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`Basado en el trabajo que cubriremos en el módulo 3, Kernel tiene como objetivo presentar el aprendizaje en un nuevo tipo de medio que integra un sistema de memoria de repetición espaciada. Eso quiere decir que volverás a revisar el material en un futuro. En un curso convencional, te olvidas de casi todo lo que aprendes a lo largo del tiempo, reteniendo quizá un par de ideas. Aunque con un sistema de aprendizaje de repetición espaciada creada en el medio, una pequeña inversión de tiempo adicional significa que te acordarás de muchísimo más. Hacer esto no será difícil, será
más fácil que la lectura inicial. Además, podrás leer otro material que está basada en estas idea. Abrirá un mundo entero.`}</p>
    <p>{`Por favor, complácenos respondiendo a las preguntas que encuentres en el texto. Para cada pregunta, piensa sobre lo que crees que la respuesta es, haz click para revelar la respuesta correcta, y luego marca si te acuerdas o no. Si te acuerdas, estupendo. Si no, tampoco pasa nada, solo anota la respuesta correcta y continua.`}</p>
    <p>{`Ese método espacio-repetición es por el cual estas preguntas solo requieren unos segundos para leer y responder. No son ejercicios complejos, similares al de un libro de texto. Más bien, las preguntas tienen un objetivo diferente: la promesa de que cada pregunta te haga recordar la respuesta para siempre. Que haga cambiar tu forma de pensar permanentemente.`}</p>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`P: ¿Cuál es mi propósito?`}</p>
        <p>{`R: ¡Cambiar tu forma de pensar! (Y para pasar la mantequilla, claro)`}</p>
      </Card>
    </Flash>
    <h2 {...{
      "id": "conversación-cultural",
      "style": {
        "position": "relative"
      }
    }}>{`Conversación cultural`}<a parentName="h2" {...{
        "href": "#conversaci%C3%B3n-cultural",
        "aria-label": "conversación cultural permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`Una conversación significativa solo puede tener lugar en una cultura de respeto y cortesía, entre personas
que sean capaces de ver sus más altos ideales en los demás y que puedan reconocer y reflejar la
igualdad esencial que se yace en el corazón de todos y cada uno de los seres humanos.`}</p>
    <p>{`Tendemos a solo establecer conversaciones cuando pensamos que podemos "llevarnos algo" de ella, bien sea conocimiento, una perspectiva distinta, o una historia interna que podemos aprovechar para nuestro beneficio. Sin embargo, conversaciones en Kernel son de `}<em parentName="p">{`humildad`}</em>{`, `}<em parentName="p">{`presencia`}</em>{`, y una `}<em parentName="p">{`atención genuina`}</em>{`
hacia los demás, no porque puedas beneficiarte de ellos, sino porque cultivar estas tres
capacidades dentro de ti es una recompensa en sí misma, la cuál te ayuda a ver claramente el completo
y perfecto núcleo sagrado de cada persona que te encuentras.`}</p>
    <p>{`Lo perfectamente completo parece defectuoso,
pero puedes usar para siempre.
Lo perfectamente lleno parece vacío,
pero no puedes usarlo.`}</p>
    <p>{`Lo verdaderamente recto se ve torcido.
Gran habilidad se ve torpe.
Elocuencia real parece tartamudear.`}</p>
    <p>{`Para estar cómoda en el frío, sigue moviéndose;
para estar cómoda en el calor, quédate quieta;
para estar cómoda en el mundo, mantén la calma y la claridad.`}</p>
    <h2 {...{
      "id": "semana-0-conversaciones-íntimas",
      "style": {
        "position": "relative"
      }
    }}>{`Semana 0 Conversaciones íntimas`}<a parentName="h2" {...{
        "href": "#semana-0-conversaciones-%C3%ADntimas",
        "aria-label": "semana 0 conversaciones íntimas permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <Column sx={{
      gap: "10%"
    }} mdxType="Column">
      <Box mdxType="Box">
        <p><strong parentName="p">{`Vitalik Buterin - Septiembre 30, 2021`}</strong></p>
        <Video src="https://www.youtube-nocookie.com/embed/zUnoKcDCnx4?start=406" mdxType="Video" />
      </Box>
      <Box mdxType="Box">
        <p><strong parentName="p">{`Andy Tudhope - Mayo 20, 2021`}</strong></p>
        <Video src="https://www.youtube-nocookie.com/embed/6uVcpfVvB3c?start=325" mdxType="Video" />
      </Box>
    </Column>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      